<template>
  <div class="contents">
    <div class="title">
      <h1 v-html="$t('common.top_title', { nickname: myInfo.user_nickname })"></h1>
      <p>{{ $t('common.top_subtitle') }}</p>
    </div>
    <div class="smenu">
      <div class="mypage_sub_title">
        <a href="javascript:" @click="this.$router.back()">
          <img src="../../assets/img/btn_back.png">
        </a>{{ $t('gift_items.title') }}
      </div>
    </div>

    <div class="warp">
      <div class="gift_items">
        <ul>
          <li>
            <img src="../../assets/img/gift_item1.png">
            <span class="item_count">x {{ myInfo.gift_item1_count }}</span>
            <span class="item_name">{{ $t('gift_items.fingerheart') }} {{ giftItemPoints[0] }} P</span>
            <PrimaryButton style="width: 84px;margin-left: 20px;" :title="$t('gift_items.buy')" @click="onBuy(0)" />
          </li>
          <li>
            <img src="../../assets/img/gift_item2.png">
            <span class="item_count">x {{ myInfo.gift_item2_count }}</span>
            <span class="item_name">{{ $t('gift_items.flowers') }} {{ giftItemPoints[1] }} P</span>
            <PrimaryButton style="width: 84px;margin-left: 20px;" :title="$t('gift_items.buy')" @click="onBuy(1)" />
          </li>
          <li>
            <img src="../../assets/img/gift_item3.png">
            <span class="item_count">x {{ myInfo.gift_item3_count }}</span>
            <span class="item_name">{{ $t('gift_items.ring') }} {{ giftItemPoints[2] }} P</span>
            <PrimaryButton style="width: 84px;margin-left: 20px;" :title="$t('gift_items.buy')" @click="onBuy(2)" />
          </li>
          <li>
            <img src="../../assets/img/gift_item4.png">
            <span class="item_count">x {{ myInfo.gift_item4_count }}</span>
            <span class="item_name">{{ $t('gift_items.palace') }} {{ giftItemPoints[3] }} P</span>
            <PrimaryButton style="width: 84px;margin-left: 20px;" :title="$t('gift_items.buy')" @click="onBuy(3)" />
          </li>
          <li>
            <img src="../../assets/img/gift_item5.png">
            <span class="item_count">x {{ myInfo.gift_item5_count }}</span>
            <span class="item_name">{{ $t('gift_items.megaheart') }} {{ giftItemPoints[4] }} P</span>
            <PrimaryButton style="width: 84px;margin-left: 20px;" :title="$t('gift_items.buy')" @click="onBuy(4)" />
          </li>
        </ul>
      </div>
      <div class="clear"></div>
    </div>
  </div>

  <GiftItemPurchasePopup v-if="showGiftItemPurchasePopup" :giftItemId="giftItemId" @close="showGiftItemPurchasePopup = false"
    @purchased="onPurchased" />
</template>

<style lang="scss" scoped src="./GiftItems.scss"></style>

<script>
import PrimaryButton from "../../components/buttons/PrimaryButton.vue";
import { GIFT_ITEM_POINTS } from "../../lib/constant";
import GiftItemPurchasePopup from "../gift_items/GiftItemPurchasePopup.vue";

export default {
  name: 'GiftItems',
  components: {
    GiftItemPurchasePopup,
    PrimaryButton,
  },
  data() {
    let myInfo = this.$store.getters.me;

    return {
      myInfo: myInfo,
      giftItemPoints: GIFT_ITEM_POINTS,
      showGiftItemPurchasePopup: false,
      giftItemId: 0,
    }
  },
  methods: {
    onBuy: function (index) {
      this.giftItemId = index;
      this.showGiftItemPurchasePopup = true;
    },
    onPurchased: function () {
      this.showGiftItemPurchasePopup = false;
    },
  },
}
</script>
